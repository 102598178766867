<template>
  <section id="bot-filter-wrapper">
    <label for="bot-filter">
      {{
        multipleSelect
          ? lang.metrics.filters.platforms.label[languageSelected]
          : lang.metrics.filters.platform.label[languageSelected]
      }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder
      :placeholder="lang.filters.platform.placeholder[languageSelected]"
      :selected-label="lang.filters.platform.selectedLabel[languageSelected]"
      :deselect-label="lang.filters.platform.deselectLabel[languageSelected]"
      label="name"
      track-by="code"
      :options="options"
      :multiple="multipleSelect"
      :taggable="true"
      :limit="1"
      :limit-text="limitText"
      :show-labels="false"
      @input="change"
    />
    <!-- @tag="addTag" -->

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
      >{{ lang.metrics.filters.range.clear[languageSelected] }}</vs-button
    >
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ALL_CHATS_PLATFORMS } from '@/constants/constants'
export default {
  name: 'PlatformFilter',
  props: {
    multipleSelect: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    startAll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  data() {
    return {
      isLoading: false,
      key: new Date(),
      platforms: [],
      value: [],
      options: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    limitText(count) {
      return `+${count}`
    },
    change() {
      let value
      if (this.multipleSelect) {
        value = this.value.length ? this.value : this.options

        this.$emit('change', {
          type: 'platform',
          value: value.map(platform => platform.code)
        })
      } else {
        value = this.value

        this.$emit('change', {
          type: 'platform',
          value: [value.code]
        })
      }
    },
    async getPlatforms() {
      this.options = ALL_CHATS_PLATFORMS
      this.key = new Date()
    },
    reset() {
      this.value = []
    },
    clear() {
      this.reset()
      this.change()
    },
    selectPlatform(serviceId) {
      this.value = []
      const plat = this.options.find(b => b.code === serviceId)
      if (this.multipleSelect) {
        this.value.push(plat)
      } else {
        this.value.push(plat.code)
      }
    }
    // addTag(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
    //   }
    //   this.options.push(tag)
    //   this.value.push(tag)
    // }
  },
  async mounted() {
    this.getPlatforms()

    // order platforms alphabetically
    this.options.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    })
    if (this.startAll) {
      const platformMap = this.options.map(p => p.code)
      this.value = []
      this.$emit('init', {
        type: 'platform',
        value: platformMap
      })
    } else if (!this.allowEmpty) {
      this.value = this.options[0]
      const platformMap = this.options.map(p => p.code)

      this.$emit('init', {
        type: 'platform',
        value: [platformMap[0]]
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#bot-filter-wrapper {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}

.multiselect__tags {
  min-height: 43px;
  max-height: 75px;
  width: 320px;
  overflow-y: auto;
}
.multiselect__tag {
  background: #ff9e43 !important;
}
.multiselect__tag-icon {
  background: #ff9e43 !important;
}
.multiselect__tag-icon:hover {
  background: #ff8102 !important;
}
.multiselect__tag-icon:after {
  color: white !important;
}
</style>
